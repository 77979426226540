<template>
	<div class="content-box">
		<div class="container">
			<div class="search screen">
				<div class="a-inline-block">
					<label for="">关键字</label><a-input v-model="searchText" class="sInput" size="large" placeholder="根据客户名称搜索"/>
				</div>
				<div class="a-inline-block">
					<label for="">客户标签</label>
					<!-- <a-input @click="showModal(2)" v-model="tagArr" class="sInput" size="large" placeholder="根据客户标签筛选"/> -->
					<a-select mode="multiple" size="large" ref="sel" v-model="tagArr" placeholder="根据客户标签筛选" :open="false" @focus="showModal(2)">
                        <!-- <a-select-option v-for="(item,index) in clientList" :key="index" :value="item.tmjcpb_corp__ids">
                            {{ item.tmjcpb_corp__name }}
                        </a-select-option> -->
                    </a-select>
				</div>
				<div class="a-inline-block">
					<a-button size="large" type="primary" @click="onSearch">搜索</a-button>
					<a-button size="large" @click="reset">重置</a-button>
				</div>
				<div class="a-inline-block">
					<a-select size="large" option-label-prop="label" v-model="screen" @change="screenChange">
						<a-select-option value="" label="全部">全部</a-select-option>
						<!-- <a-select-option value="我的">我的</a-select-option> -->
						<a-select-option v-for="item in screenList" :key="item.id" :label="item.name" :value="item.id">
							{{item.name}}
							<a-popconfirm title="确定要删除吗?" @confirm.stop="() => delSCreen(item.id)">
								<span class="f_right">
									<a-icon type="delete" />
								</span>&nbsp;&nbsp;
							</a-popconfirm>
							<span class="f_right" @click.stop="editSCreen(item.id,item.name)">
								<a-icon type="edit" />
							</span>
						</a-select-option>
					</a-select>
					<a-button size="large" class="screen_btn" @click="showScreen"> 筛选</a-button>
				</div>
			</div>
			<div class="screen tMenu">
				<div class="a-inline-block" @click="showModal(1)">
					<img src="@/assets/imgs/tMenu1.png" alt=""><label for="">打标签</label>
				</div>
				<div class="a-inline-block" @click="showAssign">
					<img src="@/assets/imgs/tMenu2.png" alt=""><label for="">指派跟进人</label>
				</div>
				<!-- <div class="a-inline-block" @click="showAdd">
					<img src="@/assets/imgs/tMenu3.png" alt=""><label for="">新建</label>
				</div> -->
				<!-- <div class="a-inline-block" @click="annexModel=true">
				<img src="@/assets/imgs/tMenu4.png" alt="" /><label for="">导入</label>
				</div> -->
				<div class="a-inline-block" @click="excelout">
					<img src="@/assets/imgs/tMenu4.png" alt=""><label for="">导出</label>
				</div>	

				<div class="a-inline-block f_right" @click="showSet">
					<img src="@/assets/imgs/set.png" alt=""><label for="">表格设置</label>
				</div>
			</div>
			
			<a-modal v-model="visible" :title="tagTitle" ok-text="确认" cancel-text="取消" @ok="hideModal" @cancel="cancelTag" >
				<div v-for="(tags,index) in allTags" :key="index">
					<label style="font-size:18px;">{{ tags.name }}</label>
					<div style="font-size:16px;margin:10px 0;">
						<template v-for="(tag,index2) in tags.options">
							<a-checkable-tag :key="index2" :checked="tag.isChecked == true" @change="handleChange(index,index2)">
								{{tag.value}}
							</a-checkable-tag>
						</template>
					</div>
				</div>
			</a-modal>
			
			
			<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :rowKey="'tmjcpb_corp__ids'" :columns="columns" :data-source="data" class="table" :pagination="pagination" :scroll="{ x: true}">
				<span slot="num" slot-scope="text, record, index"  @click="showDetail(record.tmjcpb_corp__ids,record.tmjcpb_corp__name)">
					<a href="javascript:;">{{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}</a>
				</span>
				<div slot="id" slot-scope="text,record" @click="showDetail(record.tmjcpb_corp__ids,record.tmjcpb_corp__name)">
					<img  v-if="text && text.hasOwnProperty('type') " style="max-width: 80px;" :src="text.text" alt="">
					<a v-else href="javascript:;" style="color: rgba(0, 0, 0, 0.65);">{{text}}</a>
				</div>
			</a-table>
			
			<!-- 表格设置 -->
			<table-set :setFlag="setFlag" ref="setTable" @updateFlag="updateFlag" @updateSet="updateSet"></table-set>
			<!-- 新建客户 -->
			<add-client ref="addFunc" :addFlag="addFlag" :isWap="false" @updateAddFlag="updateAddFlag" @updateClientList="updateClientList"></add-client>
			<!-- 新建联系人 @updateLinkmanList="updateLinkmanList"-->
			<add-linkman ref="addFunc2" :addFlag2="addFlag2" @updateAddFlag2="updateAddFlag2"></add-linkman>
			<!-- 客户详情 -->
			<client-detail ref="detail" :detailFlag="detailFlag" :clientId="clientId" :clientName="clientName" @updateList="updateList" @updateDetailFlag="updateDetailFlag" @updateAddFlag2="updateAddFlag2" @clientIdFunc="clientIdFunc" @linkmanIdFunc="linkmanIdFunc">
			</client-detail>
			<!-- 选择员工 -->
			<staff :staffFlag="staffFlag"  @updateStaffFlag="updateStaffFlag"></staff>
			
			<!-- 筛选功能 -->
			<screen ref="edit" :screenFlag="screenFlag" @updateScreenFlag="updateScreenFlag" @updateScreen="updateScreen"></screen>
			<a-modal v-model="annexModel" :centered="true" :footer="null" title="导入">
				<div>
					请先下载模板，按格式修改后导入
					<a-button @click="downExl()">下载模板</a-button>
				</div>
				<div>
					<label for="annex" class="annexBtn">
						<img src="@/assets/imgs/ico_daoru.png" alt="" />
						<a-button @click="$refs.annexFile.click()">导入</a-button>
					</label>
					<input type="file" id="annex" style="display: none" ref="annexFile" @change="annexUpload($event)" />
				</div>
			</a-modal>
		</div>
	</div>
</template>
<script>
	// import $ from "jquery";
	import tableSet from '../../../components/tableSet.vue';
	import AddClient from '../../../components/addClient.vue';
	import ClientDetail from '../../../components/clientDetail.vue';
	import Screen from '../../../components/screen.vue';
	import AddLinkman from '../../../components/addLinkman.vue';
	import Staff from '../../../components/staff.vue';
	import {requestXml,ajaxUrl,fileUpload,getDateTime} from '../../../assets/js/request';
	
	let tagForm = {};
	export default {
		components: {
			tableSet,
			AddClient,
			ClientDetail,
			Screen,
			AddLinkman,
			Staff
		},
		data() {
			return {
				// 打标签
				tagTitle: "",
				ids: [],
				visible: false,
				tagType: 1,
				allTags: ['Movies', 'Books', 'Music', 'Sports'],
				tagForm,
				tagArr: [],
				// 分配客户
				// assignVisible: false,
				// staff_uid: "",
				// handlerList: [],
				// department_id: "",
				// treeData : [],
				// staffname: "",
				staffFlag: false,

			
				tabledata: [],
				tablecolumns: [
					{ title: '姓名', dataIndex: 'name',scopedSlots: { customRender: 'name' }},
					{ title: '头像', dataIndex: 'avatar',scopedSlots: { customRender: 'avatar' }},
					{ title: '性别', dataIndex: 'gender'},
					// { title: '角色', dataIndex: 'role_name'},
					// { title: '所属部门', dataIndex: 'department_name', width: 500},
					// { title: '管理部门', dataIndex: 'range_name', width: 500},
					
					// {
					// 	title: '操作',
					// 	key: 'operation',
					// 	width: 150,
					// 	scopedSlots: { customRender: 'action' },
					// },
				],
				// 搜索的内容
				searchText: "",
				// 保存的筛选列表
				screenList: [],
				screen: "",
				// 表格设置展示与否
				setFlag: false,
				// 添加客户展示与否
				addFlag: false,
				// 添加联系人展示与否
				addFlag2: false,
				// 客户详情展示与否
				detailFlag: false,
				// 客户id
				clientId: 0,
				clientName: "",
				// 筛选功能展示与否
				screenFlag: false,

				data: [],
				customSatatus: ["待分配", "跟进中", "公海"],
				// 表格字段显示
				columns: [
					{
						title: '姓名',
						dataIndex: 'name',
						scopedSlots: {
							customRender: 'id'
						}
					},
					{
						title: '公司名称',
						dataIndex: 'company'
					},
					{
						title: '标签',
						dataIndex: 'tags'
					},
					{
						title: '会员等级',
						dataIndex: 'vipLevel'
					}
				],
				// 分页
				pagination: {
					current: 1,
					total: 100,
					pageSize: 10, // 默认每页显示数量
					showSizeChanger: true, // 显示可改变每页数量
					pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
					showQuickJumper: true,
					// showTotal: total => `Total ${total} items`, // 显示总数
					onChange: (current) => this.changePage(current), //点击当前页码
					onShowSizeChange: (current, pageSize) => { //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
						console.log(pageSize);
						this.showSizeChange(current, pageSize)
					},
				},
				selectedRowKeys: [],
				annexModel: false,

				detail: "",
				clienStatus:["","未跟进","跟进中","已成交","已收款","已开通","开通失败","已完成"]
			};
		},
		mounted: function() {
			this.getScreenList(1);
			this.getAll();

			// 客户详情页添加完任务时跳转过来的
			this.detail = this.$route.query.detail;
			if(this.detail == "pc" || this.detail == "opc" || this.detail == "clue"){
				this.$refs.detail.id = Number(this.$route.query.client);
				this.detailFlag = true;
				this.$refs.detail.getPortrait();
				if(this.detail == "pc"){
					this.$refs.detail.noTitleKey = "task";
					this.$refs.detail.getTask();
				}else if(this.detail == "opc"){
					this.$refs.detail.noTitleKey = "order";
					this.$refs.detail.getOrder();
				}else if(this.detail == "clue"){
					this.$refs.detail.noTitleKey = "business";
					this.$refs.detail.getBusiness();
				}
			}
		},
		methods: {
			// 分页
			changePage(current) {
				//current参数表示是点击当前的页码，
				this.pagination.current = current;
				this.getList(this.searchText, this.screen,this.tagArr); //向后端发送请求
			},
			showSizeChange(current, pageSize) {
				this.pagination.current = current;
				this.pagination.pageSize = pageSize;
				this.getList(this.searchText, this.screen,this.tagArr); //向后端发送请求
			},
			// 获取客户字段
			getAll(){
				requestXml("/scrm/corp/getfield?type=field","GET",(res) => {
					for(let i = 0;i < res.length; i++){
						if(res[i].show == true){
							res[i].scopedSlots = {customRender: 'id'};
							res[i].width = 150;
							res[i].dataIndex = res[i].name;
							res[i].title = res[i].text;
						}
						// if(res[i].name == 'tmjcpb_staff__name'){
						// 	console.log(res[i].name,'1111')
						// 	res[i].show = false
						// 	res.splice(i,0)
						// }
						// if(res[i].name == 'tmjcpb_corp__creater_uid'){
						// 	console.log(res[i].name,'22222')
						// }
					}
					res = res.filter((item) => item.name != 'tmjcpb_staff__name'); 
					this.columns = res;
					this.columns.unshift({ title: '序号', dataIndex: 'num', width: 80, align: 'center',
						scopedSlots: { customRender: 'num' },
					})
					this.getList();
				})
			},
			// 获取客户列表
			getList(key,filterid,tags) {
				requestXml("/scrm/Corp/getGhList", "POST", (res) => {
					for (let i = 0; i < res.list.length; i++) {
						// res.list[i].tmjcpb_corp__status = this.customSatatus[Number(res.list[i].tmjcpb_corp__status) - 1];

						res.list[i].tmjcpb_corp__status = this.clienStatus[Number(res.list[i].tmjcpb_corp__status)];
						// 列表中类型为时间的处理为年月日格式
						for(let j = 0;j < this.columns.length; j++){
							if(this.columns[j].type == "date"){
								res.list[i][this.columns[j].dataIndex] = getDateTime(res.list[i][this.columns[j].dataIndex]);
							}else if(this.columns[j].type == "dateTime"){
								res.list[i][this.columns[j].dataIndex] = getDateTime(res.list[i][this.columns[j].dataIndex],3);
							}else if(this.columns[j].type == "image"){
								res.list[i][this.columns[j].dataIndex] = {type:'image',text:ajaxUrl + '/scrm/index/getFile?id=' + res.list[i][this.columns[j].dataIndex]}
							}
						}
					}

					this.data = res.list;
					this.pagination.total = Number(res.page.total);
					this.pagination.pageSize = Number(res.page.pagesize);
				}, {"key": key,"filterid": filterid,"tag": tags,"page": {"curpage": this.pagination.current,"pagesize": this.pagination.pageSize}})
			},
			// 搜索
			onSearch() {
				this.pagination.current=1;
				this.getList(this.searchText, this.screen,this.tagArr);
			},
			reset(){
				this.searchText = "";
				this.screen = "";
				this.tagArr = [];
				this.getList();
			},
			// 获取保存的筛选列表
			getScreenList(type) {
				requestXml("/scrm/corp/getFilterList", "POST", (res) => {
					this.screenList = res;
					if (type == 1) {
						this.screen = "全部";
					}
				})
			},
			// 筛选下拉框
			screenChange() {
				this.screenFlag = false;
				this.getList(this.searchText, this.screen,this.tagArr);
			},
			// 查询筛选func
			getScreen() {
				requestXml("/scrm/corp/getFilter", "POST", (res) => {
					for (var i = 0; i < res.length; i++) {
						if (res[i].checked) {
							res[i].checked = res[i].checked[0];
							res[i].isChecked = true;
						}
						if(res[i].type == 'date'){
							res[i].value = getDateTime(res[i].value/1000);
						}else if(res[i].type == 'dateTime'){
							res[i].value = getDateTime(res[i].value/1000,3);
						}
					}
					this.$refs.edit.data = res;
				}, {id: this.screen})
			},
			// 编辑筛选
			editSCreen(id, name) {
				this.screenFlag = true;
				this.screen = id;
				this.$refs.edit.name = name;
				this.getScreen();
			},
			delSCreen(id) {
				requestXml("/scrm/corp/delFilter", "POST", (res) => {
					if (res == "success") {
						this.getScreenList(1);
					}
				}, {id: id})
			},
			// 筛选子组件
			showScreen: function() {
				this.screenFlag = true;
				this.$refs.edit.getScreenList();
			},
			// 子组件传值给父组件
			updateScreenFlag(data) {
				this.screenFlag = data // 改变了父组件的值
			},
			// 接收到添加的筛选 用返回的筛选id请求客户列表
			updateScreen(data) {
				// console.log("data",data)
				this.getScreenList();
				this.screen = data.name;
				this.searchText = "";
				this.tagArr = [];
				this.getList("", data.id)
			},

			// 表格全选与选中
			onSelectChange(selectedRowKeys) {
				this.ids = [];
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
				for (let i = 0; i < selectedRowKeys.length; i++) {
					this.ids.push(this.data[selectedRowKeys[i]].tmjcpb_corp__ids);
				}
			},
			// 打标签的框
			showModal(type) {
				this.tagType = type;
				this.$refs.sel.blur();
				if(type == 1){
					this.tagTitle == "打标签";
					if (this.ids.length == 0) {
						this.$message.info("请选择客户");
						return false;
					} 
				}else{
					this.tagTitle == "按标签筛选";
				}
				this.visible = true;
				requestXml("/scrm/corp/getTagOptions", "POST", (res) => {
					console.log('res', res)
					for (let i = 0; i < res.length; i++) {
						for (let j = 0; j < res[i].options.length; j++) {
							res[i].options[j].isChecked = false;
						}
					}
					this.allTags = res
				})
			},
			hideModal() {
				this.visible = false;
				this.tagArr = [];
				// 创建一个空对象存放要提交的数据，每个标签的名字做为key 将每个标签下边选中的值做为value
				for (let i = 0; i < this.allTags.length; i++) {
					let arr = [];
					for (let j = 0; j < this.allTags[i].options.length; j++) {
						if(this.allTags[i].options[j].isChecked == true){
							arr.push(this.allTags[i].options[j].value);
							
							tagForm[this.allTags[i].field] = arr.join(",");
							if(this.tagType == 2){
								this.tagArr.push(this.allTags[i].options[j].value);
							}
							
						}
					}
				}
				if(this.tagType == 1){
					requestXml("/scrm/corp/setTag", "POST", (res) => {
						this.selectedRowKeys = [];
						this.ids = [];
						
						if (res == "success") {
							tagForm = {};
							this.getList();
						}
					}, {...tagForm,"ids": this.ids})
				}else{
					// this.getList("","",this.tagArr);
					// requestXml("/scrm/Corp/getUnBindCorp", "POST", (res) => {
					// 	console.log(res)
					// },{"tag":tagArr} )
				}
			},
			cancelTag(){
				tagForm = {};
			},
			handleChange(idx,idx2) {
				console.log(idx,idx2)
				let tag =  this.allTags[idx].options[idx2].isChecked ;
				this.allTags[idx].options[idx2].isChecked = !tag;
				
				// 		if(this.allTags[idx].options[idx2].isChecked == true){
				// 			this.allTags[idx].options[idx2].isChecked = false;
				// 		}else{
				// 			this.allTags[idx].options[idx2].isChecked = true;
				// 		}
			
				console.log(this.allTags)
			},

			// 分配客户
			showAssign() {
				if (this.ids.length == 0) {
					this.$message.info("请选择客户");
					return false;
				} else {
					this.staffFlag = true;
				}
			},
			// 分配完 取消表格选中 清空客户 重新加载列表
			updateStaffFlag(data){
				console.log(data,"dasds")
				if(data != 1){
					requestXml("/scrm/corp/setStaff", "POST", (res) => {
						if (res == "success") {
							this.selectedRowKeys = [];
							this.ids = [];
							this.getList();
						}
					}, {"staff_uid": data.userid,"ids": this.ids
					})
				}
				this.staffFlag = false;
			},

			
			// 表格设置
			showSet: function() {
				this.setFlag = true;
				this.$refs.setTable.getTable();
			},
			// 子组件传值给父组件
			updateFlag(data) {
				this.setFlag = data // 改变了父组件的值
			},
			// 接收到表格设置的数据后，设置table中的字段显示与否 获取客户列表
			updateSet(data) {
				this.columns = data;
				for (let k = 0; k < this.columns.length; k++) {
					this.columns[k].scopedSlots = {customRender: 'id'};
					this.columns[k].width = 150;
				}
				this.columns.unshift({ title: '序号', dataIndex: 'num', width: 80, align: 'center',
					scopedSlots: { customRender: 'num' },
				})
				this.getList();
			},

			// 添加客户
			showAdd: function() {
				this.addFlag = true;
				this.$refs.addFunc.addClient();
			},
			// 子组件传值给父组件
			updateAddFlag(data) {
				this.addFlag = data // 改变了父组件的值
			},
			updateClientList() {
				this.getList();
			},

			// 添加联系人
			// 子组件传值给父组件
			updateAddFlag2(data) {
				this.clientId = "";
				this.addFlag2 = data // 改变了父组件的值
			},
			clientIdFunc(id) {
				//添加联系人传客户id
				console.log(this.$refs.addFunc2)
				this.$refs.addFunc2.id = id;
			},
			linkmanIdFunc(id) {
				//联系人详情 编辑联系人传联系人id
				this.$refs.addFunc2.getDetail(id);
			},
			// updateLinkmanList() {
			//     this.getList();
			// },
			// 客户编辑完，更新客户数据
			updateList: function(data){
				if(data){
					this.getList();
				}
			},
			// 客户详情
			showDetail: function(id,name) {
				this.clientId = id;
				this.clientName = name;
				this.detailFlag = true;
				this.$refs.detail.noTitleKey = "portrait";
			},
			// 子组件传值给父组件
			updateDetailFlag(data) {
				this.detailFlag = data // 改变了父组件的值
				this.detail = "";
				this.clientId = 0;
				this.clientName = "";
			},

			
			// 下载模板
			downExl() {
				window.open(ajaxUrl + "/scrm/Corp/download?type=field");
			},
			// 导入
			annexUpload(e) {
				fileUpload(e, (res) => {
					console.log(res);
					requestXml("/scrm/Corp/excelinput", "POST", (res) => {
						console.log(res);
						this.annexModel = false;
						this.$refs.annexFile.value = ''
						this.getList();
					}, {
						id: res.id
					})
				})
			},
			// 导出
			excelout() {
				console.log(ajaxUrl)
				window.open(ajaxUrl + "/scrm/corp/excelAllList?search=" + JSON.stringify(this.searchText));
			},
		},
	};
</script>
<style>
	.screen {
		text-align: left;
		margin-bottom: 10px;
	}

	.screen .sInput .ant-input{
		width: 420px!important;
	}

	.screen .ant-select {
		width: 240px;
		border-radius: 4px 0px 0px 4px;
	}

	.screen .ant-select-selection {
		border-radius: 4px 0px 0px 4px;
	}

	.screen .screen_btn {
		width: 90px;
		border-radius: 0 4px 4px 0;
	}

	.screen .ant-modal {
		width: 50%;
	}

	.tMenu {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background: #F0F6FA;
		border: 1px solid #9AD1FF;
		border-radius: 4px;
		color: #36A3FF;
		/* font-size: 16px; */
	}

	.tMenu div,
	.tMenu img,
	.tMenu label {
		cursor: pointer;
	}

	.tMenu img {
		width: 18px;
		height: 18px;
		margin: 5px 5px 5px 15px;
	}

	.tMenu .f_right {
		color: #999;
	}
	.annexBtn{display: block;margin: 20px auto;width: 200px;text-align: center;}
    .annexBtn img{display: block;width: 100px;margin: 10px auto;}

	.ant-tag{
		margin: 4px 8px 4px 0;
		border: 1px solid #d9d9d9;
		padding: 3px 7px;
	}
</style>
